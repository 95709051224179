import './App.css';
import { useState } from 'react';
import axios from 'axios';

function App() {
  const [clicks, setClicks] = useState(0);

  function populateData(data) {
    if (data !== undefined) {
      setClicks(data);
    }
  }


  function axiosTest(populateData) {
    axios.get(`https://fsdfsdfsf.s-host.net/api/clicks.php?username=yeatech`)
      .then(function (response) {
        populateData(response.data.clicks);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  axiosTest(populateData);

  return (
    <div className="App">
      <h1>Clicks : {clicks}</h1>
      <button onClick={() => setClicks(clicks + 1)}>Click on up</button>
    </div>
  );
}

export default App;
